import { graphql, Link, useStaticQuery } from 'gatsby';
import React from 'react';

const MenuItems = () => {
  const data = useStaticQuery(graphql`
    query {
      contentfulMenu {
        menuItems {
          sectionName
          sectionPath
          id
        }
      }
    }
  `);

  return (
    <div className="menuItems">
      <ul>
        <li key={'home'}>
          <Link to={`/`}>Home</Link>
        </li>
        {data.contentfulMenu.menuItems.map((item: any) => {
          if (item.sectionPath === 'section-builder') return;
          if (item.sectionPath === 'outreach')
            return (
              <li key={item.id}>
                <Link to="/contact">{item.sectionName}</Link>
              </li>
            );
          return (
            <li key={item.id}>
              <Link to={`/section/${item.sectionPath}`}>
                {item.sectionName}
              </Link>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default MenuItems;
