import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import { Helmet } from 'react-helmet';

const Head = ({ title }: any) => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  return (
    <Helmet title={`${title} | ${data.site.siteMetadata.title}`} >
      <meta name="p:domain_verify" content="f9e1fa4855cbfa7554b58512596f43d8"/>
    </Helmet>
  );
};

export default Head;
