import '@fortawesome/fontawesome-free/js/all';
import { graphql, Link, useStaticQuery } from 'gatsby';
import React from 'react';
import { Col, Grid, Row } from 'react-flexbox-grid';
import EmailForm from './email-form';
import footerStyles from './footer.module.scss';
import MenuItems from './menu-items';
import { OutboundLink } from 'gatsby-plugin-google-analytics';

const Footer = () => {
  const data = useStaticQuery(graphql`
    query {
      contentfulFooter {
        careerMessage
        chatLink
        title
        formCta
        marketText {
          marketText
        }
        contactInfo {
          phoneNumber
          careerEmail
          infoEmail
          facebook
          linkedIn
          pinterest
          twitter
          locations {
            name
          }
        }
      }
    }
  `);

  return (
    <footer>
      <Grid className={footerStyles.footer}>
        <Row>
          <Col sm={12} md={6}>
            <h1>{data.contentfulFooter.title}</h1>
          </Col>

          <Col sm={12} md={6}>
            <h3>
              {data.contentfulFooter?.formCta || data.contentfulFooter.title}
            </h3>

            <EmailForm />
          </Col>

          <div className="divider" />
        </Row>

        <Row className={footerStyles.footerLinks}>
          <Col xs={12} md={5} lg={6}>
            <MenuItems />
          </Col>

          <Col xs={12} md={7} lg={6}>
            <h3>{data.contentfulFooter.marketText.marketText}</h3>

            <Row className="noPad">
              <Col xs={6} md={4}>
                <ul>
                  {data.contentfulFooter.contactInfo.locations.map(
                    (location: any) => (
                      <li key={location.name}>{location.name}</li>
                    ),
                  )}
                </ul>
              </Col>

              <Col xs={6} md={6}>
                <ul className={footerStyles.contactLinks}>
                  <li>
                    <OutboundLink
                      href={`tel:+${data.contentfulFooter.contactInfo.phoneNumber}`}
                    >
                      {data.contentfulFooter.contactInfo.phoneNumber}
                    </OutboundLink>
                  </li>
                  <li>
                    <OutboundLink
                      href={`mailto:${data.contentfulFooter.contactInfo.infoEmail}`}
                    >
                      <i>{data.contentfulFooter.contactInfo.infoEmail}</i>
                    </OutboundLink>
                  </li>
                  <li>
                    <p className={footerStyles.career}>
                      {data.contentfulFooter.careerMessage}{' '}
                      <span>
                        <Link to={`/contact`}>
                          {data.contentfulFooter.chatLink}
                        </Link>
                      </span>
                    </p>
                  </li>
                </ul>

                <div className={footerStyles.socialLinks}>
                  <ul className={footerStyles.buttons}>
                    <li>
                      <OutboundLink
                        href={data.contentfulFooter.contactInfo.linkedIn}
                        target="_blank"
                      >
                        <i className="fab  fa-linkedin"></i>
                      </OutboundLink>
                    </li>
                    <li>
                      <OutboundLink
                        href={data.contentfulFooter.contactInfo.twitter}
                        target="_blank"
                      >
                        <i className="fab  fa-twitter"></i>
                      </OutboundLink>
                    </li>
                    <li>
                      <OutboundLink
                        href={data.contentfulFooter.contactInfo.facebook}
                        target="_blank"
                      >
                        <i className="fab  fa-facebook-f"></i>
                      </OutboundLink>
                    </li>
                    <li>
                      <OutboundLink
                        href={data.contentfulFooter.contactInfo.pinterest}
                        target="_blank"
                      >
                        <i className="fab  fa-pinterest"></i>
                      </OutboundLink>
                    </li>
                  </ul>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>

        <Row className={footerStyles.copyright}>
          <Col xs={6}>
            <Link to={'/legal'}>Privacy</Link>
          </Col>

          <Col xs={6}>
            <p className="textRight">
              &copy; {new Date().getFullYear()} Modo Carts
            </p>
          </Col>
        </Row>
      </Grid>
    </footer>
  );
};

export default Footer;
