import { graphql, useStaticQuery } from 'gatsby';
import { useForm } from "react-hook-form";
import React, { useState } from 'react';

type Inputs = {
  name: string,
  email: string,
};

const EmailForm = () => {
  const [submitted, setSubmitted] = useState(false);
  const GATEWAY_URL = 'https://vgfr0l13ga.execute-api.us-west-2.amazonaws.com/PROD';
  const { register, handleSubmit, reset, watch, errors, formState: { isSubmitting }, setError, clearErrors } = useForm<Inputs>();
  const onSubmit = (data: any) => {
    clearErrors()
    try {
      fetch(GATEWAY_URL, {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        body: JSON.stringify(data),
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
      });
      reset();
      setSubmitted(true);
    } catch (error) {
      setError("name", {
        type: "name",
        message: "Something went wrong!"
      });
    }
    return ({ errors })
  };

  const subscribeForm = (
    <form onSubmit={handleSubmit(onSubmit)} method="post" className="newsletter">
      <label htmlFor="name" className="name">
        <input
          disabled={isSubmitting}
          type="text"
          name="name"
          id="name"
          placeholder="Your Name (optional)"
          ref={register({ required: false, maxLength: 20 })}
        />
      </label>

      <div className="form-row">
        <label htmlFor="email" className="email">
          <input
            disabled={isSubmitting}
            type="email"
            name="email"
            id="email"
            placeholder="Your Email Address"
            ref={register({ required: true })}
          />
        </label> 
        <button disabled={isSubmitting} type="submit" className="btn lone-arrow" />
      </div>
      
      <div className="error">      
        {errors.email && "Email is required"}
      </div>
    </form>
  )

  const successMessage = (
    <div>
      <div className="form-row">
        <h1>You’re subscribed!</h1>

        <button onClick={() => setSubmitted(false)} disabled={isSubmitting} className="btn lone-check"/>
      </div>
    </div>
  )

  return (
    <div>
      {submitted ? successMessage : subscribeForm}
    </div>
  );
};

export default EmailForm;
